import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import HomePage from "./app/mainApp/HomePage/HomePage";
import ShopPage from "app/mainApp/ShopPage/ShopPage";
import LaPlongeusePage from "app/mainApp/La_PlongeusePage/La_PlongeusePage";
import ShopItemPage from "app/mainApp/ShopItemPage/ShopItemPage";
import OurStoryPage from "app/mainApp/OurStoryPage/OurStoryPage";
import LaButiquePage from "app/mainApp/La_ButiquePage/La_ButiquePage";
import CheckoutPage from "app/pages/CheckoutPage/CheckoutPage";
import ThankYouPage from "app/pages/ThankYouPage/ThankYouPage";
import AdminPage from "app/adminPage/adminPage";
import MainApp from "app/mainApp/MainApp";
import AdminList from "app/adminPage/adminList";
import CreateListing from "app/adminPage/CreateListing";
import Orders from "app/adminPage/Orders";
import Login from "app/pages/AuthPage/Login";
import { useContext } from "react";
import { AuthContext } from "app/Context/AuthContext";
import ScrollToTop from "app/SharedComponents/ScrollTop";
import AdminCatalogue from "app/adminPage/Catalogue/adminCatalogue";
import TermsPage from "app/mainApp/TermsPage/TermsPage";
import Sale from "app/adminPage/Sale/sale";

function App() {
  const { user } = useContext(AuthContext);
  
  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <Routes>
        <Route path="" element={<MainApp />}>
          {/* <Route index element={<HomePage />} />
          <Route path="shop" element={<ShopPage />} />
          <Route path="shop/item/:id" element={<ShopItemPage />} />
          <Route path="la_plongeuse" element={<LaPlongeusePage />} />
          <Route path="our_story" element={<OurStoryPage />} />
          <Route path="laButique" element={<LaButiquePage />} />
          <Route path="terms" element={<TermsPage />}/> */}
        </Route>
        <Route path="checkout" element={<CheckoutPage />}></Route>
        <Route path="thank_you" element={<ThankYouPage />}></Route>
        <Route path="admin" element={user ? <AdminPage /> : <Navigate to='/login'/>}>
          <Route path="" element={<Navigate to="itemList" />} />
          <Route path="itemList" element={<AdminList />} />
          <Route path="itemList/sold" element={<AdminList  isSoldComponent={true}/>} />
          <Route path="create" element={<CreateListing />} />
          <Route path="catalogue" element={<AdminCatalogue />} />
          <Route path="sale" element={<Sale />} />
          {/* <Route path="orders" element={<Orders />} /> */}
        </Route>
        <Route path="login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
